import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import firebase from '../components/firebase'
import countryList from '../components/CountryList'


export default function RegisterPage() {

    const [ats_archived, setArchived] = useState(false)
    const [ats_status, setATS] = useState('New')
    const [submitted, setSubmitted] = useState(new Date().toISOString().split('T')[0])
    const [forename, setForename] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [age, setAge] = useState('')
    const [country, setCountry] = useState('')
    const [uni, setUni] = useState('')
    const [team, setTeam] = useState('')
    const [bio, setBio] = useState('')
    const [primaryLink, setPrimaryLink] = useState('')
    const [secondaryLink, setSecondaryLink] = useState('')

    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [submitError, setSubmitError] = useState(false)

    const onSubmit = e => {
        e.preventDefault()
        firebase
        .firestore()
        .collection('team-applications')
        .add({
            ats_archived,
            ats_status,
            submitted,
            forename,
            surname,
            email,
            age,
            country,
            uni,
            team,
            bio,
            primaryLink,
            secondaryLink,
        })
        .then(() => setForename(''), setSurname(''), setEmail(''), setAge(''), setCountry(''), setUni(''), setTeam(''), setBio(''), setPrimaryLink(''), setSecondaryLink(''), setSubmitSuccess(true))
        .catch((error) => {console.log('Error adding document: ' + error); setSubmitError(true)})
    }


    return (
        <Layout title='Apply | Conex Research'>
            <div className='h-full w-screen flex justify-center px-10'>
                <form className='lg:w-1/2 flex flex-col justify-center gap-5' onSubmit={onSubmit}>

                    <div className='flex flex-col sm:flex-row gap-5'>

                        <div className='sm:w-1/2'>
                            <label for="forename" className="text-sm text-slate-700">Forename</label>
                            <input className="w-full rounded-md p-2 px-3 focus:outline-none focus:border-conex-blue border-2 border-slate-500"
                                id="forename"
                                value={forename}
                                placeholder="Forename"
                                onChange={e => setForename(e.currentTarget.value)}
                                type="text"
                            />
                        </div>

                        <div className='sm:w-1/2'>
                            <label for="surname" className="text-sm text-slate-700">Surname</label>
                            <input className="w-full rounded-md p-2 px-3 focus:outline-none focus:border-conex-blue border-2 border-slate-500"
                                id="surname"
                                value={surname}
                                placeholder="Surname"
                                onChange={e => setSurname(e.currentTarget.value)}
                                type="text"
                            />
                        </div>
                    </div>

                    <div className='flex flex-col sm:flex-row gap-5'>

                        <div className='sm:w-1/2'>
                            <label for="surname" className="text-sm text-slate-700">Email address</label>
                            <input className="w-full rounded-md p-2 px-3 focus:outline-none focus:border-conex-blue border-2 border-slate-500"
                                id="email"
                                value={email}
                                placeholder="hello@example.com"
                                onChange={e => setEmail(e.currentTarget.value)}
                                type="text"
                            />
                        </div>

                        <div className='sm:w-1/2'>
                            <label for="age" className="text-sm text-slate-700">Age</label>
                            <select class="w-full rounded-md p-2 px-3 bg-white focus:outline-none focus:border-conex-blue border-2 border-slate-500 font-medium" id='age' onChange={e => setAge(e.currentTarget.value)}  value={age}>
                                <option value={''}>Please select</option>
                                <option value={'17-20'}>17 - 20</option>
                                <option value={'21-25'}>21 - 25</option>
                                <option value={'26-30'}>26 - 30</option>
                                <option value={'30+'}>31+</option>
                            </select>
                        </div>
                        
                    </div>

                    <div className='flex flex-col sm:flex-row gap-5'>

                        <div className='sm:w-1/2'>
                            <label for="country" className="text-sm text-slate-700">Country of Residence</label>
                            <select class="w-full rounded-md p-2 px-3 bg-white focus:outline-none focus:border-conex-blue border-2 border-slate-500 font-medium" id='country' onChange={e => setCountry(e.currentTarget.value)}  value={country}>
                                <option value={''}>Please select</option>
                                {countryList.map(country => (
                                    <option value={country.code}>{country.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className='sm:w-1/2'>
                            <label for="team" className="text-sm text-slate-700">Area of interest</label>
                            <select class="w-full rounded-md p-2 px-3 bg-white focus:outline-none focus:border-conex-blue border-2 border-slate-500 font-medium" id='team' onChange={e => setTeam(e.currentTarget.value)} value={team}>
                                <option value={''}>Please select</option>
                                <option value={'science'}>Science</option>
                                <option value={'cad'}>CAD {'&'} Visualisation</option>
                                <option value={'mech'}>Mechanical Systems</option>
                                <option value={'digital'}>Digital Systems</option>
                                <option value={'syseng'}>Systems Engineering</option>
                                <option value={'ops'}>Operations</option>
                            </select>
                        </div>

                    </div>

                    <div>
                        <label for="uni" className="text-sm text-slate-700">University / Institution (Optional)</label>
                        <input className="w-full rounded-md p-2 px-3 focus:outline-none focus:border-conex-blue border-2 border-slate-500"
                            id="uni"
                            value={uni}
                            placeholder="Optional"
                            onChange={e => setUni(e.currentTarget.value)}
                            type="text"
                        />
                    </div>

                    <div className='w-full'>
                    <label for="bio" className="text-sm text-slate-700">About you</label>
                    <textarea
                        className='form-control block w-full px-3 py-1.5 text-gray-700 bg-white bg-clip-padding rounded-md transition ease-in-out focus:outline-none focus:border-conex-blue border-2 border-slate-500'
                        id="bio"
                        rows="6"
                        value={bio}
                        placeholder="A quick introduction to you and your experience"
                        maxLength={500}
                        onChange={e => setBio(e.currentTarget.value)}
                        />

                    <text className={bio.length >= 500 ? 'font-bold text-red-500': ''}>{bio.length} / 500</text>

                    </div>

                    <text className='text-sm text-slate-700'>Links to your Social Media / Projects (Optional)</text>
                    
                    <div className='flex flex-col sm:flex-row gap-5'>

                        <div className='sm:w-1/2'>
                            <input className="w-full rounded-md p-2 px-3 focus:outline-none focus:border-conex-blue border-2 border-slate-500"
                                id="primaryLink"
                                value={primaryLink}
                                placeholder="twitter.com/username"
                                onChange={e => setPrimaryLink(e.currentTarget.value)}
                                type="text"
                            />
                        </div>

                        <div className='sm:w-1/2'>
                            <input className="w-full rounded-md p-2 px-3 focus:outline-none focus:border-conex-blue border-2 border-slate-500"
                                id="secondaryLink"
                                value={secondaryLink}
                                placeholder="mycoolproject.com"
                                onChange={e => setSecondaryLink(e.currentTarget.value)}
                                type="text"
                            />
                        </div>

                    </div>

                    <text className={'font-bold text-center text-emerald-600 mt-10 ' + (submitSuccess ? 'block' : 'hidden')}>Thank you, we'll be in touch when we find the right oppurtunity.</text>
                    <text className={'font-bold text-center text-red-600 mt-10 ' + (submitError ? 'block' : 'hidden')}>Error, please try again.<br/>If issue persists email <a href='mailto:hello@conexresearch.com'>hello@conexresearch.com</a></text>

                    <button className='w-fit self-center mt-5 p-3 px-5 bg-emerald-500 disabled:bg-emerald-300 rounded-md font-bold text-white' disabled={submitSuccess}>{submitSuccess ? '🙂' : 'Submit'}</button>
                
                </form>
            </div>
        </Layout>
    )
}
